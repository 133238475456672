import { default as building_45through_45philanthropyitd07KYCgrMeta } from "/home/ron/flintrock/nuxtapp/pages/about-us/building-through-philanthropy.vue?macro=true";
import { default as financingRmASxXRdmwMeta } from "/home/ron/flintrock/nuxtapp/pages/about-us/financing.vue?macro=true";
import { default as indexIgsRHvyzN1Meta } from "/home/ron/flintrock/nuxtapp/pages/about-us/index.vue?macro=true";
import { default as our_45storyJO1T6FtS3tMeta } from "/home/ron/flintrock/nuxtapp/pages/about-us/our-story.vue?macro=true";
import { default as our_45teamPP92F4ohm3Meta } from "/home/ron/flintrock/nuxtapp/pages/about-us/our-team.vue?macro=true";
import { default as copperas_45cove_45areaxCQ6A7TaDiMeta } from "/home/ron/flintrock/nuxtapp/pages/areas/copperas-cove-area.vue?macro=true";
import { default as georgetown_45areaV6v2w72S7RMeta } from "/home/ron/flintrock/nuxtapp/pages/areas/georgetown-area.vue?macro=true";
import { default as killeen_45area4sRfk4dg7gMeta } from "/home/ron/flintrock/nuxtapp/pages/areas/killeen-area.vue?macro=true";
import { default as lorena_45areaKSBCFnRsPeMeta } from "/home/ron/flintrock/nuxtapp/pages/areas/lorena-area.vue?macro=true";
import { default as nolanville_45areaN19hOr8PAeMeta } from "/home/ron/flintrock/nuxtapp/pages/areas/nolanville-area.vue?macro=true";
import { default as salado_45areaiRTyQn9Y6gMeta } from "/home/ron/flintrock/nuxtapp/pages/areas/salado-area.vue?macro=true";
import { default as temple_45area8RLj04yIQgMeta } from "/home/ron/flintrock/nuxtapp/pages/areas/temple-area.vue?macro=true";
import { default as troy_45areaGqQV5zeKMUMeta } from "/home/ron/flintrock/nuxtapp/pages/areas/troy-area.vue?macro=true";
import { default as _91post_93dqCU6CjzpsMeta } from "/home/ron/flintrock/nuxtapp/pages/blog/[post].vue?macro=true";
import { default as indexCtajBjCIf1Meta } from "/home/ron/flintrock/nuxtapp/pages/blog/category/[category]/index.vue?macro=true";
import { default as index439VF6fH7PMeta } from "/home/ron/flintrock/nuxtapp/pages/blog/index.vue?macro=true";
import { default as _91_46_46_46slug_93pX9HxiHwsrMeta } from "/home/ron/flintrock/nuxtapp/pages/campaigns/[...slug].vue?macro=true";
import { default as index1vIg4VWAGEMeta } from "/home/ron/flintrock/nuxtapp/pages/campaigns/index.vue?macro=true";
import { default as park_45meadowsUYlLMYzbThMeta } from "/home/ron/flintrock/nuxtapp/pages/coming-soon/park-meadows.vue?macro=true";
import { default as rosenthal_45estates4gRtcn0oJBMeta } from "/home/ron/flintrock/nuxtapp/pages/coming-soon/rosenthal-estates.vue?macro=true";
import { default as templateQFIMXCAJkGMeta } from "/home/ron/flintrock/nuxtapp/pages/coming-soon/template.vue?macro=true";
import { default as index8ZEv2UJUNzMeta } from "/home/ron/flintrock/nuxtapp/pages/communities/[community]/index.vue?macro=true";
import { default as ask_45a_45question0HcYgmMkq8Meta } from "/home/ron/flintrock/nuxtapp/pages/contact-us/ask-a-question.vue?macro=true";
import { default as career_45opportunitiescwT0sENc7QMeta } from "/home/ron/flintrock/nuxtapp/pages/contact-us/career-opportunities.vue?macro=true";
import { default as faqUDzReyAFDDMeta } from "/home/ron/flintrock/nuxtapp/pages/contact-us/faq.vue?macro=true";
import { default as homeowner_45resources4OQhYMp8SnMeta } from "/home/ron/flintrock/nuxtapp/pages/contact-us/homeowner-resources.vue?macro=true";
import { default as indexXCNVCjibiVMeta } from "/home/ron/flintrock/nuxtapp/pages/contact-us/index.vue?macro=true";
import { default as our_45locationsjzKgpZKYvUMeta } from "/home/ron/flintrock/nuxtapp/pages/contact-us/our-locations.vue?macro=true";
import { default as trade_45partner_45inquiryD2WWuHsSs8Meta } from "/home/ron/flintrock/nuxtapp/pages/contact-us/trade-partner-inquiry.vue?macro=true";
import { default as current_45offers915DDJJlRdMeta } from "/home/ron/flintrock/nuxtapp/pages/current-offers.vue?macro=true";
import { default as indexCp63nEvprlMeta } from "/home/ron/flintrock/nuxtapp/pages/events/[event]/index.vue?macro=true";
import { default as index1L2OLL7t1LMeta } from "/home/ron/flintrock/nuxtapp/pages/events/index.vue?macro=true";
import { default as indexaAGOPjUERnMeta } from "/home/ron/flintrock/nuxtapp/pages/gallery/index.vue?macro=true";
import { default as home_45personality_45quizrOCUZEYw7cMeta } from "/home/ron/flintrock/nuxtapp/pages/home-personality-quiz.vue?macro=true";
import { default as indexJbhuwQy0x4Meta } from "/home/ron/flintrock/nuxtapp/pages/homes/[location]/[community]/index.vue?macro=true";
import { default as indexWgIQPnFiBqMeta } from "/home/ron/flintrock/nuxtapp/pages/homes/plans/[plan]/index.vue?macro=true";
import { default as index2IdQ6qKQX6Meta } from "/home/ron/flintrock/nuxtapp/pages/index.vue?macro=true";
import { default as land_45acquisitionJTDE03JH7WMeta } from "/home/ron/flintrock/nuxtapp/pages/land-acquisition.vue?macro=true";
import { default as _91testimonial_93UvKCzX5kp5Meta } from "/home/ron/flintrock/nuxtapp/pages/lifestyle/reviews/[testimonial].vue?macro=true";
import { default as indexFkCJwMXJ7rMeta } from "/home/ron/flintrock/nuxtapp/pages/lifestyle/reviews/category/[category]/index.vue?macro=true";
import { default as indexg0fOwPjxfXMeta } from "/home/ron/flintrock/nuxtapp/pages/lifestyle/reviews/index.vue?macro=true";
import { default as _91_46_46_46slug_93mOCtrb53q5Meta } from "/home/ron/flintrock/nuxtapp/pages/marshallfire/[...slug].vue?macro=true";
import { default as index4zZqi76eqBMeta } from "/home/ron/flintrock/nuxtapp/pages/models/[model]/index.vue?macro=true";
import { default as my_45favoritese6qYmbQFpoMeta } from "/home/ron/flintrock/nuxtapp/pages/my-favorites.vue?macro=true";
import { default as new_45home_45searchdlby6sknVXMeta } from "/home/ron/flintrock/nuxtapp/pages/new-home-search.vue?macro=true";
import { default as communities0BVLYYkEPAMeta } from "/home/ron/flintrock/nuxtapp/pages/our-homes/communities.vue?macro=true";
import { default as duplex_45collectionFwoEaFw06xMeta } from "/home/ron/flintrock/nuxtapp/pages/our-homes/home-collections/duplex-collection.vue?macro=true";
import { default as heritage_45collectionbAlOWVkkHPMeta } from "/home/ron/flintrock/nuxtapp/pages/our-homes/home-collections/heritage-collection.vue?macro=true";
import { default as heritagei_45collectiondzSDrKTXppMeta } from "/home/ron/flintrock/nuxtapp/pages/our-homes/home-collections/heritagei-collection.vue?macro=true";
import { default as legacy_45collectionPd4De5gMAwMeta } from "/home/ron/flintrock/nuxtapp/pages/our-homes/home-collections/legacy-collection.vue?macro=true";
import { default as legacyii_45collectionlNzbeF8zaZMeta } from "/home/ron/flintrock/nuxtapp/pages/our-homes/home-collections/legacyii-collection.vue?macro=true";
import { default as pioneer_45collection5adddTTQG9Meta } from "/home/ron/flintrock/nuxtapp/pages/our-homes/home-collections/pioneer-collection.vue?macro=true";
import { default as townhome_45collectionwUeAPI9x7LMeta } from "/home/ron/flintrock/nuxtapp/pages/our-homes/home-collections/townhome-collection.vue?macro=true";
import { default as indexAkz17v7ezIMeta } from "/home/ron/flintrock/nuxtapp/pages/our-homes/index.vue?macro=true";
import { default as privacytermsjaq2d3jkqWMeta } from "/home/ron/flintrock/nuxtapp/pages/privacyterms.vue?macro=true";
import { default as indexqHAeK3f9EGMeta } from "/home/ron/flintrock/nuxtapp/pages/qmi/[qmi]/index.vue?macro=true";
import { default as sandboxPnOEgfYXXpMeta } from "/home/ron/flintrock/nuxtapp/pages/sandbox.vue?macro=true";
import { default as the_45preserveiGlurKNiTIMeta } from "/home/ron/flintrock/nuxtapp/pages/the-preserve.vue?macro=true";
import { default as the_45ridge_45at_45knob_45creekFukHUNmRptMeta } from "/home/ron/flintrock/nuxtapp/pages/the-ridge-at-knob-creek.vue?macro=true";
export default [
  {
    name: "about-us-building-through-philanthropy",
    path: "/about-us/building-through-philanthropy",
    component: () => import("/home/ron/flintrock/nuxtapp/pages/about-us/building-through-philanthropy.vue")
  },
  {
    name: "about-us-financing",
    path: "/about-us/financing",
    component: () => import("/home/ron/flintrock/nuxtapp/pages/about-us/financing.vue")
  },
  {
    name: "about-us",
    path: "/about-us",
    component: () => import("/home/ron/flintrock/nuxtapp/pages/about-us/index.vue")
  },
  {
    name: "about-us-our-story",
    path: "/about-us/our-story",
    component: () => import("/home/ron/flintrock/nuxtapp/pages/about-us/our-story.vue")
  },
  {
    name: "about-us-our-team",
    path: "/about-us/our-team",
    component: () => import("/home/ron/flintrock/nuxtapp/pages/about-us/our-team.vue")
  },
  {
    name: "areas-copperas-cove-area",
    path: "/areas/copperas-cove-area",
    component: () => import("/home/ron/flintrock/nuxtapp/pages/areas/copperas-cove-area.vue")
  },
  {
    name: "areas-georgetown-area",
    path: "/areas/georgetown-area",
    component: () => import("/home/ron/flintrock/nuxtapp/pages/areas/georgetown-area.vue")
  },
  {
    name: "areas-killeen-area",
    path: "/areas/killeen-area",
    component: () => import("/home/ron/flintrock/nuxtapp/pages/areas/killeen-area.vue")
  },
  {
    name: "areas-lorena-area",
    path: "/areas/lorena-area",
    component: () => import("/home/ron/flintrock/nuxtapp/pages/areas/lorena-area.vue")
  },
  {
    name: "areas-nolanville-area",
    path: "/areas/nolanville-area",
    component: () => import("/home/ron/flintrock/nuxtapp/pages/areas/nolanville-area.vue")
  },
  {
    name: "areas-salado-area",
    path: "/areas/salado-area",
    component: () => import("/home/ron/flintrock/nuxtapp/pages/areas/salado-area.vue")
  },
  {
    name: "areas-temple-area",
    path: "/areas/temple-area",
    component: () => import("/home/ron/flintrock/nuxtapp/pages/areas/temple-area.vue")
  },
  {
    name: "areas-troy-area",
    path: "/areas/troy-area",
    component: () => import("/home/ron/flintrock/nuxtapp/pages/areas/troy-area.vue")
  },
  {
    name: "blog-post",
    path: "/blog/:post()",
    component: () => import("/home/ron/flintrock/nuxtapp/pages/blog/[post].vue")
  },
  {
    name: "blog-category-category",
    path: "/blog/category/:category()",
    component: () => import("/home/ron/flintrock/nuxtapp/pages/blog/category/[category]/index.vue")
  },
  {
    name: "blog",
    path: "/blog",
    component: () => import("/home/ron/flintrock/nuxtapp/pages/blog/index.vue")
  },
  {
    name: "campaigns-slug",
    path: "/campaigns/:slug(.*)*",
    meta: _91_46_46_46slug_93pX9HxiHwsrMeta || {},
    component: () => import("/home/ron/flintrock/nuxtapp/pages/campaigns/[...slug].vue")
  },
  {
    name: "campaigns",
    path: "/campaigns",
    component: () => import("/home/ron/flintrock/nuxtapp/pages/campaigns/index.vue")
  },
  {
    name: "coming-soon-park-meadows",
    path: "/coming-soon/park-meadows",
    component: () => import("/home/ron/flintrock/nuxtapp/pages/coming-soon/park-meadows.vue")
  },
  {
    name: "coming-soon-rosenthal-estates",
    path: "/coming-soon/rosenthal-estates",
    component: () => import("/home/ron/flintrock/nuxtapp/pages/coming-soon/rosenthal-estates.vue")
  },
  {
    name: "coming-soon-template",
    path: "/coming-soon/template",
    component: () => import("/home/ron/flintrock/nuxtapp/pages/coming-soon/template.vue")
  },
  {
    name: "communities-community",
    path: "/communities/:community()",
    component: () => import("/home/ron/flintrock/nuxtapp/pages/communities/[community]/index.vue")
  },
  {
    name: "contact-us-ask-a-question",
    path: "/contact-us/ask-a-question",
    component: () => import("/home/ron/flintrock/nuxtapp/pages/contact-us/ask-a-question.vue")
  },
  {
    name: "contact-us-career-opportunities",
    path: "/contact-us/career-opportunities",
    component: () => import("/home/ron/flintrock/nuxtapp/pages/contact-us/career-opportunities.vue")
  },
  {
    name: "contact-us-faq",
    path: "/contact-us/faq",
    component: () => import("/home/ron/flintrock/nuxtapp/pages/contact-us/faq.vue")
  },
  {
    name: "contact-us-homeowner-resources",
    path: "/contact-us/homeowner-resources",
    component: () => import("/home/ron/flintrock/nuxtapp/pages/contact-us/homeowner-resources.vue")
  },
  {
    name: "contact-us",
    path: "/contact-us",
    component: () => import("/home/ron/flintrock/nuxtapp/pages/contact-us/index.vue")
  },
  {
    name: "contact-us-our-locations",
    path: "/contact-us/our-locations",
    component: () => import("/home/ron/flintrock/nuxtapp/pages/contact-us/our-locations.vue")
  },
  {
    name: "contact-us-trade-partner-inquiry",
    path: "/contact-us/trade-partner-inquiry",
    component: () => import("/home/ron/flintrock/nuxtapp/pages/contact-us/trade-partner-inquiry.vue")
  },
  {
    name: "current-offers",
    path: "/current-offers",
    component: () => import("/home/ron/flintrock/nuxtapp/pages/current-offers.vue")
  },
  {
    name: "events-event",
    path: "/events/:event()",
    component: () => import("/home/ron/flintrock/nuxtapp/pages/events/[event]/index.vue")
  },
  {
    name: "events",
    path: "/events",
    component: () => import("/home/ron/flintrock/nuxtapp/pages/events/index.vue")
  },
  {
    name: "gallery",
    path: "/gallery",
    component: () => import("/home/ron/flintrock/nuxtapp/pages/gallery/index.vue")
  },
  {
    name: "home-personality-quiz",
    path: "/home-personality-quiz",
    component: () => import("/home/ron/flintrock/nuxtapp/pages/home-personality-quiz.vue")
  },
  {
    name: "homes-location-community",
    path: "/homes/:location()/:community()",
    meta: indexJbhuwQy0x4Meta || {},
    component: () => import("/home/ron/flintrock/nuxtapp/pages/homes/[location]/[community]/index.vue")
  },
  {
    name: "homes-plans-plan",
    path: "/homes/plans/:plan()",
    component: () => import("/home/ron/flintrock/nuxtapp/pages/homes/plans/[plan]/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/ron/flintrock/nuxtapp/pages/index.vue")
  },
  {
    name: "land-acquisition",
    path: "/land-acquisition",
    component: () => import("/home/ron/flintrock/nuxtapp/pages/land-acquisition.vue")
  },
  {
    name: "lifestyle-reviews-testimonial",
    path: "/lifestyle/reviews/:testimonial()",
    component: () => import("/home/ron/flintrock/nuxtapp/pages/lifestyle/reviews/[testimonial].vue")
  },
  {
    name: "lifestyle-reviews-category-category",
    path: "/lifestyle/reviews/category/:category()",
    component: () => import("/home/ron/flintrock/nuxtapp/pages/lifestyle/reviews/category/[category]/index.vue")
  },
  {
    name: "lifestyle-reviews",
    path: "/lifestyle/reviews",
    component: () => import("/home/ron/flintrock/nuxtapp/pages/lifestyle/reviews/index.vue")
  },
  {
    name: "marshallfire-slug",
    path: "/marshallfire/:slug(.*)*",
    component: () => import("/home/ron/flintrock/nuxtapp/pages/marshallfire/[...slug].vue")
  },
  {
    name: "models-model",
    path: "/models/:model()",
    component: () => import("/home/ron/flintrock/nuxtapp/pages/models/[model]/index.vue")
  },
  {
    name: "my-favorites",
    path: "/my-favorites",
    component: () => import("/home/ron/flintrock/nuxtapp/pages/my-favorites.vue")
  },
  {
    name: "new-home-search",
    path: "/new-home-search",
    component: () => import("/home/ron/flintrock/nuxtapp/pages/new-home-search.vue")
  },
  {
    name: "our-homes-communities",
    path: "/our-homes/communities",
    meta: communities0BVLYYkEPAMeta || {},
    alias: ["/our-homes/model-homes/","/our-homes/quick-move-in-homes/"],
    component: () => import("/home/ron/flintrock/nuxtapp/pages/our-homes/communities.vue")
  },
  {
    name: "our-homes-home-collections-duplex-collection",
    path: "/our-homes/home-collections/duplex-collection",
    component: () => import("/home/ron/flintrock/nuxtapp/pages/our-homes/home-collections/duplex-collection.vue")
  },
  {
    name: "our-homes-home-collections-heritage-collection",
    path: "/our-homes/home-collections/heritage-collection",
    component: () => import("/home/ron/flintrock/nuxtapp/pages/our-homes/home-collections/heritage-collection.vue")
  },
  {
    name: "our-homes-home-collections-heritagei-collection",
    path: "/our-homes/home-collections/heritagei-collection",
    component: () => import("/home/ron/flintrock/nuxtapp/pages/our-homes/home-collections/heritagei-collection.vue")
  },
  {
    name: "our-homes-home-collections-legacy-collection",
    path: "/our-homes/home-collections/legacy-collection",
    component: () => import("/home/ron/flintrock/nuxtapp/pages/our-homes/home-collections/legacy-collection.vue")
  },
  {
    name: "our-homes-home-collections-legacyii-collection",
    path: "/our-homes/home-collections/legacyii-collection",
    component: () => import("/home/ron/flintrock/nuxtapp/pages/our-homes/home-collections/legacyii-collection.vue")
  },
  {
    name: "our-homes-home-collections-pioneer-collection",
    path: "/our-homes/home-collections/pioneer-collection",
    component: () => import("/home/ron/flintrock/nuxtapp/pages/our-homes/home-collections/pioneer-collection.vue")
  },
  {
    name: "our-homes-home-collections-townhome-collection",
    path: "/our-homes/home-collections/townhome-collection",
    component: () => import("/home/ron/flintrock/nuxtapp/pages/our-homes/home-collections/townhome-collection.vue")
  },
  {
    name: "our-homes",
    path: "/our-homes",
    component: () => import("/home/ron/flintrock/nuxtapp/pages/our-homes/index.vue")
  },
  {
    name: "privacyterms",
    path: "/privacyterms",
    component: () => import("/home/ron/flintrock/nuxtapp/pages/privacyterms.vue")
  },
  {
    name: "qmi-qmi",
    path: "/qmi/:qmi()",
    component: () => import("/home/ron/flintrock/nuxtapp/pages/qmi/[qmi]/index.vue")
  },
  {
    name: "sandbox",
    path: "/sandbox",
    component: () => import("/home/ron/flintrock/nuxtapp/pages/sandbox.vue")
  },
  {
    name: "the-preserve",
    path: "/the-preserve",
    component: () => import("/home/ron/flintrock/nuxtapp/pages/the-preserve.vue")
  },
  {
    name: "the-ridge-at-knob-creek",
    path: "/the-ridge-at-knob-creek",
    component: () => import("/home/ron/flintrock/nuxtapp/pages/the-ridge-at-knob-creek.vue")
  }
]