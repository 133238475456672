import revive_payload_client_4sVQNw7RlN from "/home/ron/flintrock/nuxtapp/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/ron/flintrock/nuxtapp/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/ron/flintrock/nuxtapp/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/ron/flintrock/nuxtapp/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/ron/flintrock/nuxtapp/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/ron/flintrock/nuxtapp/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/home/ron/flintrock/nuxtapp/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/home/ron/flintrock/nuxtapp/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/ron/flintrock/nuxtapp/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/ron/flintrock/nuxtapp/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_eTVJQYlCmx from "/home/ron/flintrock/nuxtapp/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import _01_sentry_2snMPgo3X5 from "/home/ron/flintrock/nuxtapp/plugins/01.sentry.ts";
import _02_toast_wFzJLGyy0L from "/home/ron/flintrock/nuxtapp/plugins/02.toast.ts";
import _03_apolloErrorHandler_Q9AtkEyzLv from "/home/ron/flintrock/nuxtapp/plugins/03.apolloErrorHandler.ts";
import _04_apollo_o0ipBI8408 from "/home/ron/flintrock/nuxtapp/plugins/04.apollo.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_eTVJQYlCmx,
  _01_sentry_2snMPgo3X5,
  _02_toast_wFzJLGyy0L,
  _03_apolloErrorHandler_Q9AtkEyzLv,
  _04_apollo_o0ipBI8408
]